
// Create a model file, e.g., `dropdown-option.model.ts`
export interface DropdownOption {
  value: string;
  viewValue: string;
}
export const DROPDOWN_OPTIONS: DropdownOption[] = [
  { value: 'governorates_his', viewValue: 'Governorates History' },
  { value: 'neighbourhoods_his', viewValue: 'Neighbourhoods History' },
  { value: 'blocks_his', viewValue: 'Blocks History' },
  { value: 'streets_his', viewValue: 'Streets History' },
  { value: 'parcels_his', viewValue: 'Parcels History' },
  // { value: 'units_his', viewValue: 'Units History' },
  { value: 'project_his', viewValue: 'Project History' },
  { value: 'projects_his_with_uids', viewValue: 'Project History with UIDs' },
  { value: 'road_his', viewValue: 'Road History' },
  { value: 'master_plan_his', viewValue: 'Master Plan History' },
  { value: 'vacant_yearwise', viewValue: 'Vacant Year Wise' },
  { value: 'cluster_his', viewValue: 'Cluster History' },
  { value: 'direction_his', viewValue: 'Direction History' },
  { value: 'setback_his', viewValue: 'Setback History' },
  { value: 'sikka_his', viewValue: 'Sikka History' },
  { value: 'amenity_distance_his', viewValue: 'Amenity Distance History' },
  { value: 'parcel_visibility_his', viewValue: 'Parcel Visibility History' },
  { value: 'street_facing_rays_his', viewValue: 'Street Facing Rays History' },
];
